// Import Jquery
import $ from 'jquery';
window.$ = $;

import '~jquery-ui/jquery-ui.js';
import "~bootstrap/dist/js/bootstrap.bundle.js";
import "~rails-ujs/app/assets/javascripts/rails-ujs.js";

$(function() {
  $(".datepicker").datepicker({dateFormat : 'dd.mm.yy'});
});
